import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ClientLayout from "../../components/layout";
import CheckOut from "../../views/checkout";
import ForgetPasswordCode from "../../views/fogetpasswordcode";
import ForgetPassword from "../../views/forgetpassword";
import Home from "../../views/home";
import Login from "../../views/login";
import MyCart from "../../views/myCart";
import PasswordRecovery from "../../views/passwordrecovery";
import PaymentDetails from "../../views/paymentDetails";
import PaymentSuccess from "../../views/paymentSuccess";
import ProductDetails from "../../views/productDetails";
import ProtectedRoute from "../../views/protectedRoute";
import Shop from "../../views/shop";
import Signup from "../../views/signup";

function MyRouter() {
  // const { loading, error } = useScript(`https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`)
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route
            path="/"
            index
            element={
              <ClientLayout header={true}>
                <Home />
              </ClientLayout>
            }
          />
          <Route
            path="/shop"
            index
            element={
              <ClientLayout header={true}>
                <Shop />
              </ClientLayout>
            }
          />
          <Route
            path="/product-details/:id"
            index
            element={
              <ClientLayout header={true}>
                <ProductDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/payment-success"
            index
            element={
              <ClientLayout header={true}>
                <PaymentSuccess />
              </ClientLayout>
            }
          />
          <Route
            path="/cart"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <MyCart />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            index
            element={
              <ProtectedRoute>
                <ClientLayout header={true}>
                  <CheckOut />
                </ClientLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment-details/:orderId"
            index
            element={
              <ClientLayout header={true}>
                <PaymentDetails />
              </ClientLayout>
            }
          />
          <Route
            path="/login"
            index
            element={
              <ClientLayout header={true}>
                <Login />
              </ClientLayout>
            }
          />
          <Route
            path="/Signup"
            index
            element={
              <ClientLayout header={true}>
                <Signup />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpassword"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPassword />
              </ClientLayout>
            }
          />
          <Route
            path="/forgetpasswordcode"
            index
            element={
              <ClientLayout header={true}>
                <ForgetPasswordCode />
              </ClientLayout>
            }
          />
          <Route
            path="/passwordrecovery"
            index
            element={
              <ClientLayout header={true}>
                <PasswordRecovery />
              </ClientLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default MyRouter;
