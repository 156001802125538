import React, { useEffect, useState } from 'react';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../config/constants/api';

function Message({ content }) {
    return <p>{content}</p>;
}

function PaypalCard({ totalPrice }) {
    console.log("🚀 ~ PaypalCard ~ totalPrice:", parseInt(totalPrice).toString())

    const [total, setTotal] = useState(parseInt(totalPrice).toString())
    useEffect(() => {
        if (parseInt(totalPrice) > 0) {
            // setTotal(totalPrice)
        }
    }, [totalPrice])

    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    return (
        <div>
            <PayPalScriptProvider options={{
                // "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                "client-id": 'AWwn9vme032dhM__xFEUAWri3H8HIJ229o03AvSpMnigZ_upkWW40L6GptxNApEytmHy3y5V4SyjcngS',
                // "enable-funding": "venmo",
                // "disable-funding": "",
                // country: "US",
                // currency: "USD",
                // "data-page-type": "product-details",
                // components: "buttons",
                // "data-sdk-integration-source": "developer-studio",

                //     clientId:
                //     clientId,
                // // "enable-funding": "venmo",
                // // "disable-funding": "",
                // country: "US",
                // currency: "USD",
                // "data-page-type": "product-details",
                // components: "buttons",
                // "data-sdk-integration-source": "developer-studio",
            }}>
                {/* <Checkout /> */}
                <div>
                    <h1>Checkout</h1>
                    <PayPalButtons
                        style={{
                            shape: "rect",
                            layout: "vertical",
                            color: "gold",
                            label: "paypal",
                        }}
                        // createOrder={(data, actions) => {
                        //     return actions.order.create({
                        //         purchase_units: [
                        //             {
                        //                 amount: {
                        //                     value: "10.00", // Replace with the dynamic value
                        //                 },
                        //             },
                        //         ],
                        //     });
                        // }}
                        // onApprove={(data, actions) => {
                        //     return actions.order.capture().then((details) => {
                        //         navigate("/payment-success")
                        //         // alert(`Transaction completed by ${details.payer.name.given_name}`);
                        //         // You can handle post-payment logic here, like updating the order status
                        //     });
                        // }}
                        createOrder={async () => {
                            try {
                                debugger
                                const response = await fetch(`${BASE_URL}/orders`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    // use the "body" param to optionally pass additional order information
                                    // like product ids and quantities
                                    body: JSON.stringify({
                                        cart: [
                                            {
                                                id: "YOUR_PRODUCT_ID",
                                                // quantity: `${parseInt(totalPrice)}`,
                                                quantity: parseInt(totalPrice).toString(),
                                                // totalPrice: totalPrice
                                            },
                                        ],
                                    }),
                                });

                                const orderData = await response.json();
                                if (orderData.id) {
                                    return orderData.id;
                                } else {
                                    const errorDetail = orderData?.details?.[0];
                                    const errorMessage = errorDetail
                                        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                        : JSON.stringify(orderData);

                                    throw new Error(errorMessage);
                                }
                            } catch (error) {
                                console.error(error);
                                setMessage(
                                    `Could not initiate PayPal Checkout...${error}`
                                );
                            }
                        }}
                        onApprove={async (data, actions) => {
                            try {
                                const response = await fetch(
                                    `${BASE_URL}/orders/${data.orderID}/capture`,
                                    {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    }
                                );


                                const orderData = await response.json();
                                // Three cases to handle:
                                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                //   (2) Other non-recoverable errors -> Show a failure message
                                //   (3) Successful transaction -> Show confirmation or thank you message

                                const errorDetail = orderData?.details?.[0];

                                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                    return actions.restart();
                                } else if (errorDetail) {
                                    // (2) Other non-recoverable errors -> Show a failure message
                                    throw new Error(
                                        `${errorDetail.description} (${orderData.debug_id})`
                                    );
                                } else {
                                    // (3) Successful transaction -> Show confirmation or thank you message
                                    // Or go to another URL:  actions.redirect('thank_you.html');
                                    const transaction =
                                        orderData.purchase_units[0].payments
                                            .captures[0];
                                    setMessage(
                                        `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                                    );
                                    console.log(
                                        "Capture result",
                                        orderData,
                                        JSON.stringify(orderData, null, 2)
                                    );
                                    navigate("/payment-success")
                                }
                            } catch (error) {
                                console.error(error);
                                setMessage(
                                    `Sorry, your transaction could not be processed...${error}`
                                );
                            }
                        }}
                        onError={(err) => {
                            console.error('PayPal Checkout Error:', err);
                        }}
                    />
                </div>
            </PayPalScriptProvider>
            <Message content={message} />
        </div>

    );
}

export default PaypalCard;

const Checkout = () => {
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    return (
        <div>
            <h1>Checkout</h1>
            <PayPalButtons
                style={{
                    shape: "rect",
                    layout: "vertical",
                    color: "gold",
                    label: "paypal",
                }}
                // createOrder={(data, actions) => {
                //     return actions.order.create({
                //         purchase_units: [
                //             {
                //                 amount: {
                //                     value: "10.00", // Replace with the dynamic value
                //                 },
                //             },
                //         ],
                //     });
                // }}
                // onApprove={(data, actions) => {
                //     return actions.order.capture().then((details) => {
                //         navigate("/payment-success")
                //         // alert(`Transaction completed by ${details.payer.name.given_name}`);
                //         // You can handle post-payment logic here, like updating the order status
                //     });
                // }}
                createOrder={async () => {
                    try {
                        const response = await fetch(`${BASE_URL}/orders`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            // use the "body" param to optionally pass additional order information
                            // like product ids and quantities
                            body: JSON.stringify({
                                cart: [
                                    {
                                        id: "YOUR_PRODUCT_ID",
                                        quantity: "YOUR_PRODUCT_QUANTITY",
                                    },
                                ],
                            }),
                        });

                        const orderData = await response.json();
                        if (orderData.id) {
                            return orderData.id;
                        } else {
                            const errorDetail = orderData?.details?.[0];
                            const errorMessage = errorDetail
                                ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                                : JSON.stringify(orderData);

                            throw new Error(errorMessage);
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(
                            `Could not initiate PayPal Checkout...${error}`
                        );
                    }
                }}
                onApprove={async (data, actions) => {
                    try {
                        const response = await fetch(
                            `${BASE_URL}/orders/${data.orderID}/capture`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        );


                        const orderData = await response.json();
                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you message

                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                            // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                            return actions.restart();
                        } else if (errorDetail) {
                            // (2) Other non-recoverable errors -> Show a failure message
                            throw new Error(
                                `${errorDetail.description} (${orderData.debug_id})`
                            );
                        } else {
                            // (3) Successful transaction -> Show confirmation or thank you message
                            // Or go to another URL:  actions.redirect('thank_you.html');
                            const transaction =
                                orderData.purchase_units[0].payments
                                    .captures[0];
                            setMessage(
                                `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                            );
                            console.log(
                                "Capture result",
                                orderData,
                                JSON.stringify(orderData, null, 2)
                            );
                            navigate("/payment-success")
                        }
                    } catch (error) {
                        console.error(error);
                        setMessage(
                            `Sorry, your transaction could not be processed...${error}`
                        );
                    }
                }}
                onError={(err) => {
                    console.error('PayPal Checkout Error:', err);
                }}
            />
        </div>
    );
};
