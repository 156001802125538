import React from "react";
import { Image } from "antd";
import { ImageUrl } from "../../config/helper";
import { useNavigate, Link } from "react-router-dom";
import { UPLOADS_URL } from "../../config/constants/api";

function CardFrame({ item, index, cardText, cardAmount, products }) {
  const slideClass = ["slide-left", "slide-top", "slide-right"];
  const navigate = useNavigate();
  return (
    <>
      {/* <Link to={"/product-details"}> */}
      <div
        className={`fullcard ${index >= 0 ? slideClass[index] : ""}`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/product-details/${item?._id}`, { state: { products } });
        }}
      >
        <div className="frameSmall">
          <div className="frameheading">
            {/* <h2>{item.heading}</h2> */}
            <h2>Hero</h2>
          </div>
          <div className="frameInnerSmall">
            <Image
              preview={false}
              src={UPLOADS_URL + item?.image}
              // src={ImageUrl('frame-image.png')}
              alt="card"
            />
          </div>
        </div>
        <div className="position-relative">
          <div className="circle"></div>
        </div>
        <div className="card-text">
          <div>
            <h3>{item?.title}</h3>
            <p>
              {item?.description.length > 200
                ? item?.description?.slice(0, 200) + "..."
                : item?.description}
            </p>
          </div>

          <div className="playbtn">
            <Image
              preview={false}
              src={ImageUrl("card-play.png")}
              alt="PlayCard"
            />
          </div>
        </div>
      </div>
      {!(index>=0) && <div className="card-bottom">
        {/* <span>{cardText}</span> */}
        <span className="card-amount">$ {item?.price}</span>
      </div>}
      {/* </Link> */}
    </>
  );
}

export default CardFrame;
