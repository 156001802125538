import React from 'react'
import Header from './header'
import Footer from './Footer'
import ScrollTop from '../ScrollTop/ScrollTop'

function ClientLayout({header,children,footer=true})  {
  return (
    <>
      <ScrollTop />
    {header && <Header/>}
    {children}
   {footer && <Footer/>}
    </>
  )
}

export default ClientLayout