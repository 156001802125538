import React, { useEffect, useState } from "react";
import { Col, Image, Row, Input, Form, Modal, Button, DatePicker } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { FaCreditCard, FaRegUser } from "react-icons/fa";
import PaypalCard from "../paypal";
import { ORDER } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useSelector } from "react-redux";
import { useGetOrderByIdQuery } from "../../api/cart";



function PaymentDetails() {
  // const token = useSelector((state) => state.user.userToken);
  const { orderId } = useParams()
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  const [extra, setExtra] = useState(false)

  const { data: orderDataApi, isLoading } = useGetOrderByIdQuery(orderId)
  const orderData = orderDataApi?.order
  const totalPrice = Number(orderData?.totalPrice).toFixed(2)


  const [orderDetail, setOrderDetail] = useState({})
  const location = useLocation();
  const cart = location?.state?.cart ?? null;
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(()=>{
    setTimeout(() => {
      setExtra(true)
    }, 6000);
  },[])

  // const getOrder = async () => {
  //   const response = await Get(ORDER.placeOrder + '/');
  //   if (response?.status) {
  //     debugger
  //     setOrderDetail(response?.data?.docs)
  //     return response?.data?.docs;
  //   } else {
  //     throw new Error("Error fetching user");
  //   }
  // };

  useEffect(() => {
    try {
      console.log('bbb')
    } catch (error) {

    }
  }, [orderId, orderDataApi, orderData, totalPrice, location])

  return (
    <div className="innerpage">
      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <h5>
            <IoIosArrowDropleftCircle onClick={() => navigate("/checkout")} />
            PAYMENT DETAILS
          </h5>

          <div className="about-section product-details">
            <Row gutter={20} >
              <Col xs={24} md={14} lg={12}>

                <span className="card-title">Amount Payable</span>
                <span className="card-price">$ {totalPrice}</span>
                <Image
                  preview={false}
                  src={ImageUrl("master-cards.png")}
                  className="mt-15 mb-15 master-card"
                />
                <div className="checkout-form">
                  {/* <Form layout="vertical" name="basic">
              <Row gutter={20}>
                <Col xs={24}>
                  <Form.Item
                    label="Enter Card Holder Name"
                    name="fname"
                    rules={[
                      {
                        type: "text",
                        message: "Please Enter Card Holder Name",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Card Holder Name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Card Holder Name"
                      prefix={<FaRegUser />}
                    />
                  </Form.Item>
                </Col>
                
                <Col xs={24}>
                  <Form.Item
                    label="Enter Card Number"
                    name="number"
                    rules={[
                      {
                        type: "number",
                        message: "Please Card Number",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Card Number!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Card Number"
                      prefix={<FaCreditCard />}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Expiry Date"
                    name="number"
                    rules={[
                      {
                        type: "number",
                        message: "Please Expiry Date",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Expiry Date!",
                      },
                    ]}
                  >
                    <DatePicker onChange={onChange}  style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 11px",
                        background:"#fff",
                        height:"50px",
                        borderRadius:"0",
                      }} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Enter CVV"
                    name="number"
                    rules={[
                      {
                        type: "number",
                        message: "Please CVV",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Valid CVV!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter CVV"
                      prefix={<FaCreditCard />}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Col xs={24} className="mt-15"><Button className="mainbtn" onClick={showModal}>PAY NOW</Button></Col>
      
            </Form> */}
                  {(totalPrice > 0) ? <PaypalCard totalPrice={totalPrice}/> : null}
                </div>
              </Col>
              <Col xs={24} md={10} lg={12}>
                <Image
                  preview={false}
                  src={ImageUrl("payment-card.png")}
                  className="mb-15"
                />
              </Col>

            </Row>
          </div>
        </Col>
      </Row>

      <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Image
          preview={false}
          src={ImageUrl("tick.png")}
          className="mt-15 mb-15 tickimage"
        />
        <h4>PAYMENT SUCCESS</h4>
        <p>Your payment has been made successfully!</p>

        <Col xs={24} className="flex mt-15"><Button className="mainbtn" onClick={() => navigate("/")}>CONTINUE</Button></Col>
      </Modal>
    </div>
  )
}

export default PaymentDetails