import React from 'react'
import { Row, Col, Image, Button } from "antd";
import { ImageUrl } from "../../config/helper";
import { useNavigate,Link } from "react-router-dom";

function BottomSection() {
  return (
    <div className='bottom-section'>
        <Row justify={"center"}>
        <Col xs={22} md={20}>
        <Row gutter={20}>
            <Col xs={24} md={7} lg={8}>
             <Image  preview={false}  src={ImageUrl("tilt-image.png")} />
            </Col>
            <Col xs={24} md={17} lg={16}>
                <h3>
                    Want to See the full list of cards?
                </h3>
                <h4>get the card checklist!</h4>
                <Link to="/shop"><Button className="mainbtn">Shop Now</Button>  
             </Link>
            </Col>
        </Row>
        </Col>
      </Row>
        
    </div>
  )
}

export default BottomSection