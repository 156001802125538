import React, { useEffect, useState } from "react";
import { Col, Row, Menu, Image, Button, Popover, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import { ImageUrl } from "../../config/helper";
import { FaShoppingCart, FaUser } from "react-icons/fa";
import WOW from "wowjs";
import "animate.css";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/slice/authSlice";

const items = [
  {
    label: "Home",
    key: "/",
  },
  {
    label: "About Us",
    key: "/",
  },
  {
    label: "My Buxx Coins",
    key: "/",
  },
  {
    label: "NIT Cards Auction",
    key: "/",
  },
  {
    label: "Shop",
    key: "/shop",
  },
];

function Header({ header }) {
  const token = useSelector((state) => state.user.userToken);
  console.log("🚀 ~ Header ~ token:", token)
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, []);

  const content = (
    <div>
      <Button className="profile_btn" onClick={() => navigate("/profile")}>
        Profile
      </Button>
      <Button className="profile_btn" onClick={() => {
        dispatch(removeUser());
        navigate("/login");
      }}>Logout</Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key);
    setCurrent(e.key);
  };

  return (
    <Row justify={"center"} className="navigation">
      <Col xs={22} lg={20}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={12} sm={12} lg={4} xl={4}>
            <Image
              className="logo"
              preview={false}
              src={ImageUrl("logo.png")}
              alt="logo"
            />
          </Col>
          <Col xs={0} sm={0} md={0} lg={13} xl={14} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>
          <Col xs={12} sm={12} lg={7} xl={6} justify={"end"}>
            <Row style={{ width: "100%", padding: "0px 50px" }}></Row>

            <Row align={"middle"}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ textAlign: "right" }}
              >
                <MenuOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => showDrawer()}
                />
              </Col>
              <Col lg={24} md={0} sm={0} xs={0}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {token ? <>
                    {/* {false && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 40 }}>
                      <Image className="logo" preview={false} src={ImageUrl("dollar.png")} alt="banner" width={60} height={60} />
                      <p style={{ color: 'white' }}>$ {userData?.amountBalance}</p>
                    </div>} */}
                    <FaShoppingCart onClick={() => navigate("/cart")} style={{marginRight: 30}}/>
                    <Popover content={content} style={{ width: '100%' }}>
                      <p style={{ color: 'white' }}>Account</p>
                    </Popover></>
                    :
                    <>
                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/Signup")}
                      >
                        Register
                      </Button>

                      <Button
                        className="mainbtn"
                        onClick={() => navigate("/login")}
                      >
                        <FaUser /> LOGIN{" "}
                      </Button>
                    </>}

                  {/* <Popover content={content} style={{width: '100%'}}>
                    <Button className="profile">

                      <Image className="logo" preview={false} src={ImageUrl("logo.png")} alt="banner" />
                    </Button>
                  </Popover> */}
                  {/* {!token && <Button
                    className="mainbtn"
                    onClick={() => navigate("/Signup")}
                  >
                    Register
                  </Button>}

                  {!token && <Button
                    className="mainbtn"
                    onClick={() => navigate("/login")}
                  >
                    <FaUser /> LOGIN{" "}
                  </Button>} */}
                </div>
              </Col>
            </Row>
            {/* */}

            <Drawer
              placement={"left"}
              closable={true}
              onClose={onClose}
              open={open}
              key={"left"}
              className="menu-drawer"
            >
              <Menu
                className="mainMenu "
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
              <div
                className="mobileprofile"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                <Popover content={content}>
                  {/* <Button className="profile">
                <Image className="logo" preview={false}  src={ImageUrl("logo.png")} alt="banner" />
              </Button> */}
                </Popover>
                {!token ? <Link to="/">

                  <Button className="mainbtn" onClick={(e) => {
                    try {
                      e.preventDefault()
                      navigate("/Signup")
                      setOpen(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}>Register</Button>
                </Link>: <Button className="mainbtn" onClick={(e) => {
                    try {
                      e.preventDefault()
                      dispatch(removeUser());
                      navigate("/")
                      setOpen(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}>Logout</Button>}
                {!token &&
                  <Button className="mainbtn" onClick={(e) => {
                    try {
                      e.preventDefault()
                      navigate("/login")
                      setOpen(false)
                    } catch (error) {
                      console.log(error)
                    }
                  }}>
                    <FaUser /> LOGIN{" "}
                  </Button>
                }
              </div>
            </Drawer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;
