import React, { useEffect } from "react";
import { Button, List, Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetMyCartQuery } from "../../api/cart";

function OrderSummary({ subTotal = 0, tax = 0, CheckOut, cart, loading }) {
  const navigate = useNavigate();
  const location = useLocation()
  const { data: cartData, refetch } = useGetMyCartQuery("")
  console.log("🚀 ~ OrderSummary ~ cartData:", cartData)
  const totalPrice = Number(cartData?.cart?.totalPrice ?? 0).toFixed(2)
  console.log("🚀 ~ OrderSummary ~ totalPrice:", totalPrice)

  useEffect(()=>{
    refetch()
  },[loading])
  
  return (
    <div className="summary">
      <h5>ORDER SUMMARY</h5>
      <div className="summaryinner">
        <List itemLayout="horizontal">
          <List.Item>
            <List.Item.Meta
              title={
                <div className="summaryList">
                  {" "}
                  <span>Subtotal</span> <span>$ {totalPrice}</span>
                </div>
              }
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              title={
                <div className="summaryList">
                  {" "}
                  <span>Grand Total</span> <span>$ {totalPrice}</span>
                </div>
              }
            />
          </List.Item>
        </List>
        {location?.pathname === "/cart" ? <Button
          className="mainbtn"
          onClick={() => {
            navigate("/checkout", { state: { cart } })
          }}
        >
          Proceed To Checkout
        </Button> :null}
        {/* {CheckOut && (
          <Button className="mainbtn" onClick={() => navigate("/checkout")}>
            Proceed To Checkout
          </Button>
        )} */}
      </div>
    </div>
  );
}

export default OrderSummary;
