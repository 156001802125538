import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentSuccess.css'; // Optional CSS for styling

const PaymentSuccess = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className="innerpage">
    <div className="payment-success">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase.</p>
      <p>Your order is being processed, and you will receive a confirmation email soon.</p>

      <div className="success-details">
        <h3>Order Summary</h3>
        <p>Order Number: #12345</p> {/* Replace with actual order number */}
        <p>Total Amount: $120.00</p> {/* Replace with actual total amount */}
      </div>

      <button className="back-home-btn" onClick={handleBackToHome}>
        Back to Home
      </button>
    </div>
    </div>
  );
};

export default PaymentSuccess;
