import React, { useState } from "react";
import { Row, Col, Image } from "antd";
import { ImageUrl } from "../../config/helper";
import CardFrame from "../../components/card";
import AboutSection from "../../components/AboutSection";
import { Link } from "react-router-dom";
import { RiArrowDownDoubleLine } from "react-icons/ri";
import ProductSlider from "../../components/productSlider";
import BottomSection from "../../components/bottomSection";
import CarouselSlider from "../../components/carousel";
import { useQuery } from "react-query";
import { Get } from "../../config/api/get";
import { PRODUCTS } from "../../config/constants/api";

const getRandomThree = (array = []) => {
  return array
    .sort(() => 0.5 - Math.random()) // Shuffle array
    .slice(0, 3); // Take the first 3 items
};
function Home() {
  const getProducts = async () => {
    const response = await Get(PRODUCTS.getAllProducts);
    if (response?.status) {
      return response?.data?.docs;
    } else {
      throw new Error("Error fetching user");
    }
  };
  const { data, error, isLoading } = useQuery(
    "getAllProducts", // Unique query key based on the user ID
    getProducts,
    {
      retry: 1, // Optional: Retry once if the query fails
      onError: (err) => console.log(err),
    }
  );
  const randomProducts = Array.isArray(data) ? getRandomThree(data) : [];
  const [cardFrame, SetCardFrame] = useState([
    {
      key: 1,
      image: "frame-image.png",
      btnimg: "card-play.png",
      heading: "Hero",
      para: "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
      title: "Splash",
    },

    {
      key: 2,
      image: "frame-image.png",
      btnimg: "card-play.png",
      heading: "Hero",
      para: "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
      title: "Splash",
    },
    {
      key: 3,
      image: "frame-image.png",
      btnimg: "card-play.png",
      heading: "Hero",
      para: "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
      title: "Splash",
    },
  ]);

  return (
    <div>
      <div className="position-relative">
        <video autoPlay loop muted className="bg-vid">
          {" "}
          <source src={ImageUrl("video-1.mp4")} type="video/mp4" />{" "}
        </video>
      </div>
      <div className="banner">
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <Row justify={"center"} gutter={20}>
              {Array.isArray(randomProducts) &&
                randomProducts.length > 0 &&
                randomProducts.map((item, index) => {
                  return (
                    <Col xs={22} md={12} lg={6}>
                      <CardFrame item={item} index={index} products={Array.isArray(data) ? data : []} />
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xs={22} md={20}>
            <h1>
              Browse Sword
              <br />& Shield Expansion{" "}
              <Link to="#about">
                Explore More <br />
                <RiArrowDownDoubleLine />
              </Link>
            </h1>
          </Col>
        </Row>
      </div>

      <Row justify={"center"}>
        <Col xs={22} md={20} className="text-center">
          {/* <Image preview={false} src={ImageUrl("giveawaybanner.png")} alt="" /> */}
        </Col>
      </Row>

      <Row justify={"center"}>
        <Col xs={22} md={20}>
          <AboutSection />
        </Col>
      </Row>

      {/* {Array.isArray(data) ? data : [] && (
        <ProductSlider
          title={"Buy Your Favorite Card"}
          heading={"live Auction"}
          products={data}
        />
      )} */}

      <BottomSection />
    </div>
  );
}

export default Home;
