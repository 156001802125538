import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from './slice/authSlice';
import { cartApi } from '../api/cart';


const rootReducer = combineReducers({
  user: userReducer,
  [cartApi.reducerPath]: cartApi.reducer,
})

const persistConfig = {
  key: 'real_money_dragon_user',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cartApi.middleware),
})

export const persistor = persistStore(store)