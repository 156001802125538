import React from "react";
// import AuthLayout from "../../components/";
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from "antd";
import { useNavigate } from "react-router";
import { ImageUrl } from "../../config/helper";
import { FaEnvelope } from "react-icons/fa";

// import router from "next/router";

function ForgetPassword() {
//   const dispatch = useDispatch();
  const navigate = useNavigate();
//   const user = useSelector((state) => state.user.userData);
//   const token = useSelector((state) => state.user.userToken);
//   const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
//   React.useEffect(() => {
//     if (user && token) {
//       navigate("/", { replace: true });
//     }
//   }, [user, token]);

//   const onFinish = (values) => {
//     console.log("Success:", values);
//     return;
//     setLoading(true);

//     let data = {
//       email: values.email,
//       password: values.password,
//     };
//     Post(AUTH.signin, data)
//       .then((response) => {
//         console.log(response, "#####");
//         setLoading(false);
//         if (response?.data?.status) {
//           navigate("/dashboard", { replace: true });

//           dispatch(
//             addUser({
//               user: response.data.data.user,
//               token: response.data.data.token,
//             })
//           );
//           swal("Success", response.data.message, "success");
//         } else {
//           console.log("response", response);
//           swal("Oops!", response.data.message, "error");
//         }
//       })
//       .catch((e) => {
//         console.log(":::;", e);
//         setLoading(false);
//       });
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };

  return (
    <>
    <div className="position-relative">
        <video autoPlay loop muted className="bg-vid">
          <source src={ImageUrl("video-1.mp4")} type="video/mp4" />
        </video>
      </div>
      <Layout className="banner">
      <Row className="flex">
        <Col  xs={22} md={12} lg={8}>
          <Card>
            <div className="authFormBox checkout-form">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                 

                  
                  <h3>FORGOT PASSWORD</h3>
                    <p>Did you forgot your password?</p>
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 0,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    // autoComplete="off"
                  >
                    <Form.Item
                    label="Enter Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please Enter Email Address",
                        // warningOnly: true,
                      },
                      {
                        required: true,
                        message: "Please Enter Email Address!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Email Address"
                      prefix={<FaEnvelope />}
                    />
                  </Form.Item>

                   
                    <br />

                    <Form.Item>
                      <Button onClick={() => navigate("/forgetpasswordcode")}
                        type="primary"
                        htmlType="submit"
                        className="loginButton mainbtn"
                      >
                        Continue
                        {/* {loading ? "Loading..." : "Continue"} */}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "14px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <>
                    Back To  <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                          color: "#000",
                        }}
                      >
                       Login
                      </span>
                    </>
                  </Typography.Text>

                  
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
    </>
    
  );
}

export default ForgetPassword;
