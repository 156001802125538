import { Col, Image, Row, Skeleton, Table, Button } from "antd";
import { useEffect, useState } from "react";
import OrderSummary from "../../components/orderSummary";
import QuantitySelector from "../../components/quantitySelector";
import { ImageUrl } from "../../config/helper";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { Get } from "../../config/api/get";
import { CART, ORDER, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { Put } from "../../config/api/put";
import swal from "sweetalert";
import { RxCross1 } from "react-icons/rx";
import { Post } from "../../config/api/post";

function MyCart() {
  const [cart, setCart] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([
    {
      _id: 1,
      image: "frame-image.png",
      product: "Abc Product",
      quantity: 10,
      unitPrice: "180",
      size: "Small",
      color: "Silver",
      totalPrice: 100,
    },
    {
      _id: 2,
      image: "frame-image.png",
      product: "Abc Product",
      quantity: 10,
      unitPrice: "180",
      size: "Small",
      color: "Silver",
      totalPrice: 100,
    },
  ]);

  useEffect(() => {
    getCart();
  }, []);
  const handleSelectedQuantityChange = (value, index) => {
    let productId = cart?.products[index]?.product._id;
    let data = {
      productId,
      quantity: value,
    };
    Put(CART.updateProduct, token, data)
      .then((response) => {
        if (response?.success) {
          getCart();
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
    // let _orders = [...orders];
    // _orders[index].quantity = value;
    // setOrders(_orders);
  };
  const removeCartItem = (e, value) => {
    let data = {
      productId: value?.product?._id
    }
    Post(CART.removeProduct, data, token)
      .then((response) => {
        if (response?.success) {
          // swal("System Alert", response?.message, "success");
          // navigate(`/payment-details/${response?.order?._id}`);
          getCart();
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message || err?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const getCart = async () => {
    setLoading(true);
    Get(CART.getMyCart, token)
      .then((response) => {
        if (response?.success) {
          console.log(response);
          setCart(response?.cart ?? []);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching Cart", err);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: ["product", "image"],
      key: "image",
      width: 100,
      render: (item) => (
        <Image
          preview={false}
          src={UPLOADS_URL + item}
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "cover", objectPosition: "center" }}
        />
      ),
    },
    {
      title: "PRODUCT Name",
      dataIndex: ["product", "title"],
      key: "title",
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (item, value, index) => (
        <QuantitySelector
          value={item}
          onChange={(e) => handleSelectedQuantityChange(e, index)}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (item, value) => <span>${value.quantity * value.price}</span>,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (item, value) => <RxCross1 onClick={(e)=>{removeCartItem(e, item)}}/>,
    },
  ];

  useEffect(()=>{
    console.log('my cart page')
  },[cart, cart?.products, loading, token])
  return (
    <>
      <div className="innerpage">
        <Row justify="center">
          <Col xs={22} md={20} xl={20}>
            <h5>
              <IoIosArrowDropleftCircle onClick={() => navigate("/shop")} /> My
              Cart
            </h5>
            <Row style={{}} justify="center">

            
            </Row>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}></Col>

              <Col xs={22}>
                <Row gutter={30}>
                  <Col xs={24} md={15} lg={17}>
                    <Row style={{ padding: 20, overflow: "auto" }}>
                      {/* <Col xs={24} style={{ height: '100%' }}>
                      <p className="text-center" style={{color: 'white'}}>No Items to show</p>
                      </Col> */}
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Skeleton active />
                          <br />
                        </div>
                      ) : cart?.products?.length > 0 ? (
                        <Table
                          className="styledTable2"
                          dataSource={cart?.products}
                          columns={columns}
                          pagination={false}
                          style={{
                            backgroundColor: "transparent",
                          }}
                          rowClassName={() => "transparent-row"}
                        />
                      ) : (
                      <p className="text-center" style={{color: 'white'}}>No Items to show</p>
                      // null
                        // <p style={{color: 'white'}}>No Items to show</p>
                      )}
                    </Row>
                  </Col>
                  <Col xs={24} md={9} lg={7}>
                    <OrderSummary
                      subTotal={cart?.totalPrice}
                      tax={0}
                      cart={cart}
                      loading={loading}
                    />
                  </Col>
                </Row>

                <Button className="shopinbtn" onClick={() => navigate("/shop")}>
                  Continue Shopping
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MyCart;
